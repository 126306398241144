// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.4.0",
  "buildNumber": "19331",
  "gitSha": "7b29ddc2a8235b61172ca62130e8a920893f577f",
  "fullVersion": "24.4.0-19331",
  "formattedVersion": "24.4.0 (19331-7b29ddc2a8235b61172ca62130e8a920893f577f)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
